import { useEffect } from 'react'
import { Helmet } from 'react-helmet'

import { ReactComponent as AvalancheIcon } from '../assets/images/avalanche.svg'
import { ReactComponent as AlchemyUniIcon } from '../assets/images/alchemy_u.svg'
import { ReactComponent as SolanaUniIcon } from '../assets/images/solana_u.svg'
import Layout from '../components/Layout'
import styles from '../styles/pages/Achievements.module.scss'

const Achievements = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.type = 'module'
    script.src = `js/grid.js?${Date.now()}`
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Layout isRelativeFooter>
      <div className={styles.achievements}>
        <Helmet>
          <title>0xHanson | Achievements</title>
        </Helmet>
        <div className={styles.achievements__grid}>
          <div className='grid'>
            <div className='grid__item'>
              <a
                target='_blank'
                rel='noopener noreferrer'
                className='grid__item-img'
                href='https://academy.avax.network/course/avalanche-fundamentals'
              >
                <div className='grid__item-img-deco'></div>
                <AvalancheIcon className={styles.achievements__avax_img} />
              </a>
              <p className='grid__item-label'>Completed Avalanche Fundamentals Certification</p>
              <span className='grid__item-tag'>Avalanche Academy</span>
            </div>
            <div className='grid__item'>
              <a
                target='_blank'
                rel='noopener noreferrer'
                className='grid__item-img'
                href='https://twitter.com/SolanaUni/status/1662831162191118337'
              >
                <div className='grid__item-img-deco'></div>
                <SolanaUniIcon className={styles.achievements__solana_u_img} />
              </a>
              <p className='grid__item-label'>Ranked #4 in the Challenger at Solana Taipei Hacker House</p>
              <span className='grid__item-tag'>Solana University</span>
            </div>
            <div className='grid__item'>
              <a
                target='_blank'
                rel='noopener noreferrer'
                className='grid__item-img'
                href='https://polygonscan.com/tx/0xcfa9ce55f50e94457f6e79e58056b6c030f8fbaf56ddb1a113c39a644f069d1f'
              >
                <div className='grid__item-img-deco'></div>
                <AlchemyUniIcon className={styles.achievements__alchemy_u_img} />
              </a>
              <p className='grid__item-label'>Earned my Certification as an Alchemy Certified Ethereum Developer</p>
              <span className='grid__item-tag'>Alchemy University</span>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Achievements
