import { useEffect, useCallback } from 'react'
import { useMetaMask } from 'metamask-react'
import { NavLink, useLocation } from 'react-router-dom'
import AnimatedCursor from 'react-animated-cursor'

import { ReactComponent as TwitterXIcon } from '../assets/images/twitter_x.svg'
import { ReactComponent as OpenSeaIcon } from '../assets/images/opensea.svg'
import { ReactComponent as AkaSwapIcon } from '../assets/images/akaswap.svg'
import styles from '../styles/components/Layout.module.scss'

const METAMASK_STATUS = {
  INITIALIZING: 'initializing',
  UNAVAILABLE: 'unavailable',
  NOT_CONNECTED: 'notConnected',
  CONNECTING: 'connecting',
  CONNECTED: 'connected',
}

const MAINNET_ID = '0x1'
const MY_ADDRESS = '0x54486ed1db65Bc55e0cc049eBd9ba03eaBf08fDC'

const Layout = ({
  isRelativeFooter,
  isLeftNavVisible,
  children,
}) => {
  const {
    status,
    connect,
    account,
    chainId,
    switchChain,
  } = useMetaMask()

  const location = useLocation()

  const getAngles = (clientX, clientY) => {
    const menu = document.querySelector(`.${styles.layout}`)
    const menuRect = menu.getBoundingClientRect()
    const { x, y, width, height } = menuRect
    const dx = clientX - (x + 0.5 * width)
    const dy = clientY - (y + 0.5 * height)
    return { dx, dy }
  }

  const setAngles = useCallback(({ clientX, clientY }) => {
    const { dx, dy } = getAngles(clientX, clientY)
    const menu = document.querySelector(`.${styles.layout}`)
    menu?.attributeStyleMap?.set('--x', `${dy / 20}deg`)
    menu?.attributeStyleMap?.set('--y', `${dx / 20}deg`)
  }, [])

  useEffect(() => {
    if (location.pathname === '/' || location.pathname === '/projects') {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [location])

  useEffect(() => {
    window.addEventListener('mousemove', setAngles)

    return () => window.removeEventListener('mousemove', setAngles)
  }, [setAngles])

  useEffect(() => {
    if (status === METAMASK_STATUS.CONNECTED) {
      if (chainId !== MAINNET_ID) {
        switchChain(MAINNET_ID)
      }
    }
  }, [status, chainId, switchChain])

  useEffect(() => {
    const script = document.createElement('script')
    script.type = 'module'
    script.src = `https://cdn.rawgit.com/pierregoutheraud/metamask-transaction-button/6ebebf41/build/static/js/mtb.js?${Date.now()}`
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const footerWrapperClassName = isRelativeFooter
    ? styles.layout__relative_footer_wrapper
    : styles.layout__footer_wrapper

  return (
    <div className={styles.layout}>
      <AnimatedCursor
        color='206, 196, 237'
      />
      <div className={styles.layout__login_status}>
        {status === METAMASK_STATUS.INITIALIZING && (
          <div className={styles.layout__login_text}>
            Synchronisation with MetaMask ongoing...
          </div>
        )}
        {status === METAMASK_STATUS.UNAVAILABLE && (
          <div className={styles.layout__login_text}>
            Probably Nothing
          </div>
        )}
        {status === METAMASK_STATUS.NOT_CONNECTED && (
          <button onClick={connect}>Connect to MetaMask</button>
        )}
        {status === METAMASK_STATUS.CONNECTING && <div className={styles.layout__login_text}>Connecting...</div>}
        {status === METAMASK_STATUS.CONNECTED && (
          <div className={styles.layout__login_text}>
            <div>Connected:</div>
            <div>
              <a target='_blank' rel='noopener noreferrer' href={`https://etherscan.io/address/${account}`}>
                {account}
              </a>
            </div>
          </div>
        )}
      </div>
      <div className={styles.layout__nav}>
        <NavLink
          to='/'
          title='Home'
        >
          Home
        </NavLink>
        <NavLink
          to='/achievements'
          title='Achievements'
        >
          Achievements
        </NavLink>
        <NavLink
          to='/poap'
          title='POAP'
        >
          POAP
        </NavLink>
        <NavLink
          to='/projects'
          title='Projects'
        >
          Projects
        </NavLink>
      </div>
      {isLeftNavVisible && (
        <div className={styles.layout__left}>
          <a target='_blank' rel='noopener noreferrer' href='https://x.com/sunsquarez' title='X'>
            <TwitterXIcon />
          </a>
          <a target='_blank' rel='noopener noreferrer' href='https://opensea.io/0xHanson' title='OpenSea'>
            <OpenSeaIcon />
          </a>
          <a target='_blank' rel='noopener noreferrer' href='https://akaswap.com/tz/tz1bYKtxjBeQ1wnTLrEN9ormSacR78eWdPqD' title='akaSwap'>
            <AkaSwapIcon />
          </a>
        </div>
      )}
      {children}
      <div className={footerWrapperClassName}>
        <div className={styles.layout__footer}>
          <div>
            <a href='mailto:gm@0xhanson.xyz'>gm@0xhanson.xyz</a>
            <div>
              {/* eslint-disable-next-line */}
              <span onClick={() => navigator.clipboard.writeText('sunsquarez.sol')}>sunsquarez.sol</span>
            </div>
          </div>
          <div>© Created by <a target='_blank' rel='noopener noreferrer' href='https://hanson-wu.com'>Hanson Wu</a></div>
        </div>
        {status !== METAMASK_STATUS.UNAVAILABLE && (
          <div className={styles.layout__metamask_donate}>
            <div
              className='metamask-button'
              address={MY_ADDRESS}
              text='Tip'
              amount='0.001'
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Layout
