import React from 'react'
import ReactDOM from 'react-dom/client'
import { MetaMaskProvider } from 'metamask-react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import Home from './pages/Home'
import Achievements from './pages/Achievements'
import Poap from './pages/Poap'
import Projects from './pages/Projects'
import './styles/index.scss'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/achievements',
    element: <Achievements />,
  },
  {
    path: '/poap',
    element: <Poap />,
  },
  {
    path: '/projects',
    element: <Projects />,
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <MetaMaskProvider>
      <div className='spotify-player'>
        <iframe
          title='spotify'
          src='https://open.spotify.com/embed/album/60alGywktBV9G3ZU4DCcwN?utm_source=generator&theme=0'
          width='100%'
          height='80'
          frameBorder='0'
          allowtransparency='true'
        />
      </div>
      <RouterProvider router={router} />
    </MetaMaskProvider>
  </React.StrictMode>
)
